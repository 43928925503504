/* ============================ */
/*            Home
/* ============================ */

.recent_activity {
  small {
    font-size: 11px;
  }
}

.stat-icon i {
  font-size: 36px;
}

.rt_icon_card {
  padding: 10px;
  span {
    font-weight: 600;
  }
  p {
    font-size: 16px;
    font-weight: 500;
  }
  .icon_rt {
    float: left;
    width: 60px;
    position: relative;
    z-index: 1;
    height: 60px;
    background: #3644FF;
    border-radius: 10px;
    i {
      line-height: 60px;
      margin-bottom: 0;
      font-size: 38px;
      color: #fff;
    }
  }
}

.traffic-sales-amount {
  float: right;
  color: #3d405c;
}

.rt_subheader {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 20px 30px;
  background: #fff;
  border-radius: 10px;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  .rt_subheader_main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding: 0;
    .rt_subheader_title {
      margin: 0;
      padding: 0 1.5rem 0 0;
      font-size: 16px;
      font-weight: 700;
      color: #434349;
    }
    .kt-subheader__separator {
      display: block;
      width: 12px;
      height: 4px;
      border-radius: 2px;
      background: rgba(149, 156, 182, 0.5);
      margin: 0 1.5rem 0 0;
    }
    .rt_breadcrumb {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      list-style-type: none;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      margin: 0;
      padding: 0;
      .rt_breadcrumb_home_icon {
        padding: 0px 0.65rem 0px 0px;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        i {
          font-size: 14px;
          color: #959cb6;
        }
        &:hover {
          i {
            -webkit-transition: all 0.3s;
            transition: all 0.3s;
            color: theme-color-main(primary);
          }
        }
      }
      .rt_breadcrumb_separator {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 0.45rem 0 0;
        color: rgba(149, 156, 182, 0.5);
      }
      .breadcrumb_link {
        padding: 0 0.35rem 0 0;
        font-size: 14px;
        font-weight: 500;
        color: #959cb6;
        -webkit-transition: all 0.3s;
        transition: all 0.3s;
        &:hover {
          color: theme-color-main(primary);
          -webkit-transition: all 0.3s;
          transition: all 0.3s;
        }
      }
    }
  }
}

.analytics_card {
  p {
    font-size: 15px;
    font-weight: 600;
  }
  .stats_icon {
    background: rgba(255,255,255,0.4);
    padding: 2px 10px;
    border-radius: 50px;
    margin-left: 6px;
  }
  .icon-rounded {
    width: 3.75rem;
    height: 3.75rem;
    border-radius: 50%;
    text-align: center;
    background: white;
    i {
      font-size: 1.625rem;
      line-height: 3.75rem;
    }
  }
}

.total_sales_sec {
  margin-bottom: 20px;
  background: #f9f9f9;
  padding: 10px 20px;
  border-radius: 10px;
  @media (max-width: 768px) {
    display: block !important;
  }
}

.sales-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  margin: .5em 0;
  @media (max-width: 768px) {
    -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  }
}

.sales-info .sales-info-box {
  margin-top: 3px;
  height: 20px;
  width: 20px;
  border-radius: 10px;
}

#country_map{
  height: 200px;
}

.country_vists {
  margin-top: 40px;
  .country_vists_item {
    margin-bottom: 15px;
    .country_vists_wrapper {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-bottom: 5px;
      .country_vists_title {
        color: theme-color-main(dark);
        font-weight: 500;
        font-size: 14px;
      }
      .country_vists_value {
        color: theme-color-main(dark);
        font-weight: 500;
        font-size: 14px;
        padding-left: 5px;
      }
    }
    .country_vists_progress .progress {
      height: 6px;
    }
  }
}

.avg_sale_card {
  h1 {
    font-size: 30px;
  }
  h4 {
    font-size: 16px;
  }
}

.traffic_progress {
  .title {
    font-size: 14px;
    font-weight: 600;
  }
  .badge {
    font-size: 12px;
  }
  .progress {
    height: 6px;
  }
}

#morris_line svg text {
  font-family: Montserrat;
}

/* ============================ */
/*            End Home
/* ============================ */